import { AdminState } from "@/store/admin/state";
import { ISystemAdminSettings } from "@/interfaces/system";
import { State } from "@/store/state";
import { getStoreAccessors } from "typesafe-vuex";

export const mutations = {
    setSystemSettings(state: AdminState, payload: ISystemAdminSettings) {
        state.systemSettings = payload;
    }
};

const { commit } = getStoreAccessors<AdminState, State>("");

export const commitSetSystemSettings = commit(mutations.setSystemSettings);
