import { AdminState } from "@/store/admin/state";
import { State } from "@/store/state";
import { getStoreAccessors } from "typesafe-vuex";

export const getters = {
    systemSettings: (state: AdminState) => state.systemSettings,
};

const { read } = getStoreAccessors<AdminState, State>("");
export const readSystemSettings = read(getters.systemSettings);

