import { commitAddNotification, commitRemoveNotification } from "../main/mutations";

import { ActionContext } from "vuex";
import { AdminState } from "@/store/admin/state";
import { ISystemAdminSettings } from "@/interfaces/system";
import { State } from "@/store/state";
import api from "@/api";
import { commitSetSystemSettings } from "@/store/admin/mutations";
import { dispatchCheckAPIError } from "@/store/main/actions";
import { getStoreAccessors } from "typesafe-vuex";

type AdminContext = ActionContext<AdminState, State>;

export const actions = {
  async actionReadSystemAdminSettings(context: AdminContext, force: boolean) {
    try {
      const response = await api.readSystemAdminSettings();

      commitSetSystemSettings(context, response.data)
      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error);
      return null;
    }
  },

  async actionUpdateSystemAdminSettings(context: AdminContext, payload: ISystemAdminSettings) {
    const loadingNotification = { content: "Updating system settings...", color: "info", showProgress: true };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.updateSystemAdminSettings(payload);

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: "System settings updated", color: "success" });

      return response.data;
    } catch (error) {
      commitAddNotification(context, { content: `Couldn't update system settings. ${error}`, color: "error" });
    }
    return null;
  }
};

const { dispatch } = getStoreAccessors<AdminState, State>("");

export const dispatchUpdateSystemAdminSettings = dispatch(actions.actionUpdateSystemAdminSettings);
export const dispatchReadSystemAdminSettings = dispatch(actions.actionReadSystemAdminSettings);